import React from "react";
import { Link } from "gatsby";

import Layout from "@rocketseat/gatsby-theme-docs/src/components/Layout";
import SEO from "@rocketseat/gatsby-theme-docs/src/components/SEO";

export default function Index() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>Page not found!</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
      <p>
        If you&#39;d like to go back to homepage,
        <Link to="/">click here</Link>.
      </p>
    </Layout>
  );
}
